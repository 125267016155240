.circle {
    width: 20vw;
    height: 20vw;
    line-height: 25vw;
    background-color: #4CDA64;
    border-radius: 50%;
    text-align: center;
}

.circle_red {
    width: 20vw;
    height: 20vw;
    line-height: 25vw;
    background-color: #FF3A2F;
    border-radius: 50%;
    text-align: center;
}

.circle_name {
    width: 20vw;
    text-align: center;
    color: white;
}

.call {
    position: absolute;
    top: 75vh;
    right: 15vw;
    padding: 10px;
}

.call_rejection {
    position: absolute;
    top: 75vh;
    left: 15vw;
    padding: 10px;
}
.circle_icon {
    width: 50vw;
    height: 50vw;
    line-height: 25vw;
    background-image: url(./shio.jpeg);
    border-radius: 50%;
    text-align: center;
    background-size: 50vw 50vw;
    background-repeat: no-repeat;
}

.icon {
    position: absolute;
    top: 20vh;
    right: 25vw;
}

.icon_anime {
    position: absolute;
    top: 20vh;
    right: 25vw;
    z-index: 10;
}

.circle_icon_anime {
    width: 50vw;
    height: 50vw;
    line-height: 25vw;
    border-radius: 50%;
    text-align: center;
    border: solid 2px white;
    transition: 1s;
    animation: zoom-in 1s infinite;
}

@keyframes zoom-in {
	0% {
	    transform: scale(1);
        opacity: 1;
	}
	100% {
	    transform: scale(1.2);
        opacity: 0;
	}
}